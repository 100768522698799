import React from 'react';
import { graphql } from 'gatsby';
import { withLang } from '../../utils/reactTools';
import { Contact } from '../contact';

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/pages/contact.md/"}) {
      fields {
        locales {
          fr {
            pageTheme
            ...SeoFragmentFR
            ...heroBannerFragmentFR
            cards {
              cardTitle
              cardText
              cardButtonTitle
              cardButtonLink
            }
          }
        }
      }
    }
  }
`;

export default withLang(Contact, 'fr');
