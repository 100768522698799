import React, { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import { Cta } from '../Cta/Cta';
import { Heading } from '../Heading';
import { StyledCard } from './StyledCard';



export function Card({
  title,
  content,
  button
}: PropsWithChildren<CardProps>): JSX.Element {
  if (button?.title) {
    button.title = button.title.trim();
  }

  if (button?.url) {
    button.url = button.url.trim();
  }

  const textTransform = () => {
    return {
      a: (props: LinkProps) => {
        const { href, children } = props;
        if (href.startsWith('http')) {
          return <a href={href} target="_blank">{props.children}</a>
        } else {
          return <a href={href}>{props.children}</a>
        }
      }
    }
  }



  return (
    <StyledCard data-cursor-color="var(--charcoal)">
      {title && (
        <Heading tag="h3" className="title">{title}</Heading>
      )}

      {content && (
        <ReactMarkdown components={{ ...textTransform() }} className="content">
          {content}
        </ReactMarkdown>
      )}

      {button && (button.title || button.url) && (
        <Cta
          href={button.url}
          className="cta">
          {button.title}
        </Cta>
      )}
    </StyledCard>
  );
}

interface LinkProps {
  children: string[];
  href: string;
  node: any;
}

interface CardProps {
  title?: string;
  content?: string;
  button?: {
    title: string;
    url: string;
  };
}
