import { graphql } from 'gatsby';
import React from 'react';
import { ContactData, ContactPage } from '../components/ContactPage/ContactPage';
import { Page } from '../components/Page';
import { SEO, SEOProps } from '../components/SEO/SEO';
import { paths } from '../utils/paths';
import { withLang } from '../utils/reactTools';
import { getLocaleMdData } from '../utils/Tools';

export const Contact: React.FC<ContactProps> = ({ data, language }) => {
  const _data = getLocaleMdData(data);
  const { pageTheme, seo } = _data[language] || {};

  const globalSEO: SEOProps = (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title, description, ogImage } = (seo ? seo[0] : null) || {};

  return (
    <Page
      background={pageTheme}
      language={language}
      slugs={{ en: `${paths.en.prefix}/contact`, fr: `/${paths.fr.prefix}/contact` }}
    >
      <SEO
        title={title ? title : globalSEO.title}
        description={description ? description : globalSEO.description}
        ogImage={ogImage ? ogImage : globalSEO.ogImage} />

      <ContactPage data={data} />
    </Page>
  )
  //#endregion
}

interface ContactProps extends LocalizedMarkdownData<ContactData>, PropsWithLang<ContactData> { }

export const pageQuery = graphql`
  {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/pages/contact.md/"}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
            pageTheme
            ...heroBannerFragmentEN,
            cards {
              cardTitle
              cardText
              cardButtonTitle
              cardButtonLink
            }
          }
        }
      }
    }
  }
`;

export default withLang(Contact, 'en');
