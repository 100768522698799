import React, { useContext } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { getLocaleMdData } from '../../utils/Tools';
import { Card } from '../Card/Card';
import { HeroBanner, HeroBannerData } from '../HeroBanner/HeroBanner';
import { PactContext } from '../PactContext';
import { SEOProps } from '../SEO/SEO';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledContactPage } from './ContactPage.style';

export const ContactPage: React.FC<ContactPageProps> = ({ data }): JSX.Element => {
  const { language } = useContext(PactContext);
  const _data = getLocaleMdData(data);

  const {
    heroBanner,
    cards = []
  } = _data[language] || {};

  return (
    <StyledContactPage>
      <HeroBanner data={heroBanner[0]} />

      <Wrapper className="wrapper" width={1358}>
        <ul className="cards">
          {cards.map((card, i) => {
            return (
              <li key={(card.cardText ?? card.cardText) + i} className="cards__item">
                <ShowOnScroll
                  delay={(100 * i) + 50}
                  observerOptions={{ rootMargin: '0px 0px -150px' }}
                >
                  <Card
                    title={card.cardTitle}
                    content={card.cardText}
                    button={{
                      title: card.cardButtonTitle,
                      url: card.cardButtonLink
                    }} />
                </ShowOnScroll>
              </li>
            );
          })}
        </ul>
      </Wrapper>
    </StyledContactPage>
  );
}

interface ContactPageProps {
  data: LocalizedMarkdownData<ContactData>['data'];
}

export interface ContactData {
  pageTheme: ThemeBG;
  seo: SEOProps[];
  heroBanner: HeroBannerData[];
  cards: ContactCard[];
}

export interface ContactCard {
  cardTitle: string;
  cardText: string;
  cardButtonTitle: string;
  cardButtonLink: string;
}
