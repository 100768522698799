import React, { PropsWithChildren } from 'react';

const ALLOWED_HEADING_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

export function Heading({ tag, children, ...props }: PropsWithChildren<HeadingProps>): JSX.Element {
  const Tag: AllowedHeadings = tag;

  if (!ALLOWED_HEADING_TAGS.includes(tag)) {}

  return (
    <Tag {...props}>
      { children }
    </Tag>
  );
}

type AllowedHeadings = typeof ALLOWED_HEADING_TAGS[number];

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  tag: AllowedHeadings;
}
