import styled from 'styled-components';
import { desktopHover, pxtorem, pxtovw } from '../../utils/Tools';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: max(${pxtovw(76)}, 52px) max(${pxtovw(80)}, 25px) max(${pxtovw(60)}, 60px);
  background-color: var(--pebble);
  border-radius: 10px;

  .title {
    font: ${ pxtorem(48) }/1.2 var(--primaryFont);
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .content {
    font: ${ pxtorem(20) }/1.5 var(--secondaryFont);
    margin: 0 0 40px;

    a {
      display: inline-block;
      line-height: 1;
      vertical-align: baseline;
      border-bottom: 1px solid var(--aqua);
      transition: color .1s linear;

      ${desktopHover(`
        color: var(--aqua);
      `)}
    }
  }

  .cta {
    --hoverColor: var(--black);
    align-self: flex-start;
    margin-top: auto;
    
    ${desktopHover(`
        color: var(--pebble);
      `)}
  }

  * {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  @media (max-width: 767px) {
    padding: 52px 25px 60px;
    text-align: center;

    .title {
      font-size: 36px;
    }

    .content {
      margin-bottom: 32px;
    }
    
    .cta {
      align-self: center;
    }
  }
`;
