import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem } from '../../utils/Tools';
import { StyledCard } from '../Card/StyledCard';
import { StyledHeroBanner } from '../HeroBanner/HeroBanner.style';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';

export const StyledContactPage = styled.div`
  ${ StyledHeroBanner } {
    ${ Wrapper } {
      --width: 1358px;
    }
  }

  > ${ Wrapper } {
    padding-top: 40px;
    padding-bottom: ${ pxtorem(200) };

    @media (max-width: 991px) {
      padding-bottom: 120px;
    }

    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    list-style: none;
    padding: 0;

    &__item {
      ${ StyledShowOnScroll }, ${ StyledCard } {
        height: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    ${ StyledHeroBanner } {
      .text {
        letter-spacing: -0.3px;
      }
    }

    .cards {
      grid-template-columns: 1fr;
    }

    ${ StyledShowOnScroll } {
      transition-delay: 0s !important;
    }
  }
`;
